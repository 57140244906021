import React from 'react';
import './Skills.css';

const Skills = ({ skillsData }) => {
  const getProficiencyLevel = (proficiency) => {
    // Determine proficiency level based on the number of filled segments
    if (proficiency <= 39) return 'Beginner';
    if (proficiency <= 59) return 'Skilled';
    if (proficiency <= 79) return 'Intermediate';
    if (proficiency <= 99) return 'Advanced';
    return 'Expert';
  };

  return (
    <section className="skills">
      <h2 className="text-uppercase">
        <i className="fa fa-puzzle-piece"></i> Skills
      </h2>
      <div className="skills-container">
        {skillsData.map((category, categoryIndex) => (
          <div key={categoryIndex} className="skills-category">
            <h3 className="category-name">
              {category.name} <span className="category-level"></span>
            </h3>
            <div className="skills-grid">
              {category.keywords.map((item, skillIndex) => (
                <div className="skill" key={skillIndex}>
                  <div className="skill-name">{item.skill}</div>
                  <div className="segmented-bar">
                    {/* Tooltip with text-based proficiency */}
                    <div className="tooltip">{getProficiencyLevel(item.proficiency)}</div>
                    {Array.from({ length: 5 }).map((_, segmentIndex) => (
                      <div
                        key={segmentIndex}
                        className={`segment ${
                          (segmentIndex + 1) * 20 <= item.proficiency ? 'filled' : ''
                        }`}
                      ></div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
