import React from 'react';
import ModelViewer from '../components/ModelViewer';

const ModelViewerPage = () => {
  return (
    <div className="model-viewer-page">
      <a href="/" className="back-button">
        <i className="fa fa-arrow-left"></i> Back to CV
      </a>
      <ModelViewer />
    </div>
  );
};

export default ModelViewerPage;
