import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ModelViewerPage from './pages/ModelViewerPage';
import { object } from 'prop-types';
import Profile from './sections/Profile';
import About from './sections/About';
import Work from './sections/Work';
import Skills from './sections/Skills';
import Education from './sections/Education';

import './App.css';

const App = (props) => {
  const {
    jsonObj: { basics, work, skills, education },
  } = props;

  const profileData = basics;
  const aboutData = profileData.summary;
  const workData = work;
  const skillsData = skills;
  const educationData = education;

  // State for Dark Mode
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Toggle Dark Mode
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);

    // Add/remove dark-mode class from <body>
    if (!isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  };

  return (
    <Router basename="/">
      <div className="app-container">
        <Routes>
          <Route path="/model-viewer" element={<ModelViewerPage />} />
          <Route
            path="/"
            element={
              <div>
                <button className="dark-mode-toggle" onClick={toggleDarkMode}>
                  <i className={`fas ${isDarkMode ? 'fa-sun' : 'fa-moon'}`}></i>
                </button>
                <div className="container">
                  <aside>
                    <div className="inner">
                      <Profile profileData={profileData} />
                    </div>
                  </aside>
                  <main>
                    <div className="inner">
                      <About aboutData={aboutData} />
                      <Work workData={workData} />
                      <Skills skillsData={skillsData} />
                      <Education educationData={educationData} />
                    </div>
                  </main>
                </div>
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

App.propTypes = {
  jsonObj: object.isRequired,
};

export default App;
